
        @import "./styles/matcha.scss";
      
.background {
  min-width: 100vw;
  // Fallback CSS
  min-height: calc(100vh - 72px);
  min-height: calc(100dvh - 72px);
  background: var(--dg-color-background);
}
