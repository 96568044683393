
        @import "./styles/matcha.scss";
      
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import '~@axieinfinity/matcha/dist/styles/scss/root';
@import '~@axieinfinity/matcha/dist/styles/scss/reboot';
@import '~@axieinfinity/matcha/dist/matcha.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans antialiased;
}

@layer base {
  html {
    color: var(--mc-text-color);
    font-feature-settings: 'calt' off;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    background: var(--bg);
  }

  input {
    transition: ease 0.2s !important;
  }

  button:focus {
    outline: none !important;
  }
}

@layer components {
  .matcha-overlay {
    border-radius: 12px;
    background: rgba(12, 12, 13, 0.8);
  }

  .matcha-interaction-inner-shadow {
    box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.2) inset,
      0px 0px 30px 0px rgba(255, 255, 255, 0.02) inset;
    backdrop-filter: blur(9px);
  }

  .matcha-background-blur {
    backdrop-filter: blur(12.5px);
  }

  .matcha-focus {
    box-shadow: 0px 0px 0px 2px #0c48c0;
  }

  .matcha-active {
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1);
  }

  .matcha-divider-top {
    box-shadow: 0px 1px 0px 0px #272b34 inset;
  }

  .matcha-divider-bottom {
    box-shadow: 0px -1px 0px 0px #272b34 inset;
  }

  .matcha-divider-right {
    box-shadow: -1px 0px 0px 0px #272b34 inset;
  }

  .mb-6 {
    margin-bottom: 6px;
  }
}

/* --------------- Custom styles -------------- */
@keyframes three-dots {
  0%,
  20% {
    color: transparent;
    text-shadow: 0.25em 0 0 transparent, 0.5em 0 0 transparent;
  }
  40% {
    color: var(--mc-theme-text-color);
    text-shadow: 0.25em 0 0 transparent, 0.5em 0 0 transparent;
  }
  60% {
    text-shadow: 0.25em 0 0 var(--mc-theme-text-color), 0.5em 0 0 transparent;
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 var(--mc-theme-text-color),
      0.5em 0 0 var(--mc-theme-text-color);
  }
}

.three-dots-loading:after {
  content: '.';
  animation: three-dots 2s steps(5, end) infinite;
}

:root {
  --max-width: 480px;
  --max-height: 600px;
  --form-max-width: 480px;

  @media only screen and (max-width: 480px) {
    --max-width: 100vw;
    --max-height: calc(100vh - 120px);
  }

  @media print {
    body {
      * {
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
        color-adjust: exact !important; /* Firefox 48 – 96 */
        print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
      }
    }
  }
}

.center-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// OVERRIDE CSS CAPTCHA

.axie-captcha-dialog {
  @media only screen and (max-width: 768px) {
    padding: 16px;
  }
}

.axie-captcha-dialog-wrapper {
  .axie-captcha-dialog-content {
    overflow: auto;
    max-height: 100vh;
  }
}
